.circle {
    height: 24px;
    width: 24px;
    border-radius: 24px;
    background-color: black;
    position: fixed; 
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 99999999; /* so that it stays on top of all other elements */
  }