.Footer-container {
  min-width: 100vw;
  min-height: 75vh;
  background-color: transparent;
  background-image: url("../Media/Footer_Bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 40px;
  align-items: center;
  padding-bottom: 0%;
  padding-top: 5%;
}

.footer-section {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  text-align: center;
  justify-content: center;
}

.footer-logo-div {
  width: 20%;
  margin-top: -5%;
  margin-bottom: 5%;
  justify-content: left;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .footer-logo-div {
    margin-top: 10%;
  }
}

.footer-logo {
  display: block;
  margin: auto;
  width: 100%;
  filter: drop-shadow(1px 1px 2px rgb(22, 15, 15));
  filter: brightness(1.2);
}

.contact-div {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  justify-content: center;
  align-items: center;
}

.contact-details {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 25px;
}

.contact-det-section {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: inherit;
  align-items: center;
  justify-content: space-between;
}

.detail-input,
.message-input {
  padding: 10px;
  background-color: #1b0303e1;
  border: solid 1px #1b0101;
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
  font-family: Inder;
  padding-left: 20px;
  border-radius: 10px;
  width: 300px;
  color: white;
  font-size: 90%;
}

.message-input {
  height: 25vh;
}

.submit-button {
  background-color: rgba(137, 136, 136, 0.601);
  padding: 10px;
  width: 25%;
  border: none;
  border-radius: 2px;
  font-family: Inder;
  font-size: 110%;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(137, 136, 136, 0.601);
}

.submit-button:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(49, 1, 1, 0.601);
  background-color: #1b0303e1;
  color: white;
}

.quick-links {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.quick-link-head {
  font-family: Inder;
  color: white;
  font-size: 150%;
  text-decoration: underline;
}

.quick-link-body {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.quick-link {
  color: #a1a0a0;
  font-family: Inder;
  font-size: 100%;
  text-decoration: none;
}

.contact-detail-div {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: left;
  padding: 30px;
  width: 300px;
}

.detail {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  padding-left: 15px;
}

.detail-icon {
  width: 30px;
  height: 30px;
  color: #a1a0a0;
  filter: drop-shadow(2px 2px 5px rgba(240, 229, 229, 0.333));
}

.detail-text {
  color: #a1a0a0;
  font-family: Inder;
  font-size: 80%;
  text-align: left;
  width: 300px;
}

.detail-separator {
  width: 30vw;
  border-radius: 100px;
  height: 1px;
  border: none;
  background-color: #777272;
  margin: auto;
}

.social-media-icons {
  display: flex;
  flex-direction: row;
  column-gap: 25px;
  justify-content: center;
  align-items: center;
}

.sm-icon {
  width: 30px;
  height: 30px;
  color: #a1a0a0;
  filter: drop-shadow(2px 2px 5px rgba(240, 229, 229, 0.333));
  cursor: pointer;
}

.success-msg-box {
  width: 100vw;
  height: 100vh;
  background-color: rgb(183, 127, 127, 0.5);
  position: fixed !important;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  backdrop-filter: blur(2px);
  display: none;
}

.success-inner-msg-box {
  background-color: white;
  border-radius: 15px;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 20px;
  row-gap: 10px;
  box-shadow: 2px 2px 10px rgb(159, 159, 159);
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 250px;
}

.success-img {
  width: 100%;
  height: 95%;
  display: block;
  margin: auto;
}

.success-ok {
  padding: 5px;
  width: 20px;
  border-radius: 5px;
  background-color: transparent;
  font-family: Inder;
  display: block;
  float: right;
}

.success-message {
  font-family: Inder;
  font-size: 120%;
  padding: 10px;
  text-align: center;
  color: rgb(125, 8, 8);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .Footer-container {
    background-size: cover;
    padding-top: 15%;
  }
  .success-msg-box {
    animation: none;
  }
}

@media screen and (max-width: 600px) {
  .Footer-container {
    background-size: cover;
    padding-top: 5%;
  }
}

@keyframes appear {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
