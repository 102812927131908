@import url("https://fonts.googleapis.com/css2?family=Inder");
@import url("https://fonts.googleapis.com/css2?family=Kodchasan");

.Way-container {
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 3%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  position: sticky;
}

.way-head {
  font-family: Kodchasan;
  font-size: 300%;
  color: black;
  text-align: center;
}

.highlight {
  color: #b20c0c;
}

.way-canva {
  background-color: aquamarine;
  position: absolute;
  margin-top: -50vh;
  height: 80vh;
}

.steps-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.step-section {
  width: 35vw;
  text-align: center;
}

.steps-separator {
  width: 95%;
  border-radius: 100px;
  color: black;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.step-sec2 .steps-separator {
  float: right;
}

.way-step {
  margin-left: 3%;
}

.step-head {
  color: #d90606;
  font-family: Inder;
  font-size: 120%;
  padding: 10px;
  padding-bottom: 5px;
  text-align: left;
}

.text-left {
  text-align: right;
}

.step-body {
  font-family: Inder;
  font-size: 100%;
  padding: 10px;
  padding-top: 5px;
  text-align: justify;
}

.step-sec1 .step-body {
  padding-left: 30px;
}

.way-toy {
  margin-left: 1%;
  position: absolute;
  perspective: 300px;
  transform: scale(0.8);
  transform-style: preserve-3d;
  transition: ease-in-out 2s;
}

@media screen and (max-width: 1100px) {
  .way-toy {
    height: 50vh;
  }
}

.way-step:hover {
  font-weight: bold;
  cursor: default;
}

#no-toy-disp {
  transition-duration: 2s;
  display: none;
}

#toy-disp {
  transition-duration: 2s;
  display: block;
}

.additional {
  display: none;
}

@media screen and (max-width: 900px) {
  .way-toy {
    margin-top: 15%;
  }
}

@media screen and (max-width: 720px) {
  .way-toy,
  #toy-disp {
    display: none;
  }
  .steps-sections {
    flex-direction: column;
  }
  .step-section {
    width: 95vw;
  }
  .additional {
    display: flex;
  }
}

@media screen and (max-width: 1024px) {
  .Way-container {
    min-height: 60vh;
  }
}

@media screen and (max-width: 600px) {
  .way-head {
    font-size: 180%;
  }
  .step-head {
    font-size: 110%;
  }
  .step-body {
    font-size: 90%;
  }
  .text-left {
    text-align: left;
  }
  .step-sec2 .step-body {
    padding-left: 30px;
  }
}
