.Founders-container {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.founders-heading {
  font-family: Kodchasan;
  font-size: 270%;
  text-align: center;
  text-transform: capitalize;
  color: #b20c0c;
  background-color: #efeded;
  padding-top: 20px;
}

.founders-box {
  width: 60vw;
  height: 55vh;
  box-shadow: 3px 3px 10px rgba(158, 158, 158, 0.571);
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.founder-carousel {
  width: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.control-arrow.control-prev,
.control-arrow.control-next {
  color: red !important; /* Ensure color is red */
}

.carousel .control-arrow.control-prev,
.carousel .control-arrow.control-next {
  background-color: rgb(183, 127, 127, 0.5);
}

.carousel .control-arrow.control-prev:hover,
.carousel .control-arrow.control-next:hover {
  background-color: #b20c0c;
}

.team-member-slide {
  width: 95%;
  height: 55vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}

.team-member-pic {
  width: 40%;
  background-size: contain;
  background-repeat: no-repeat;
}

.team-member-details {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  row-gap: 10px;
  padding: 10px;
}

.team-member-name {
  font-family: Inder;
  color: #b20c0c;
  font-size: 200%;
  text-transform: capitalize;
  background-image: linear-gradient(to bottom, #ba2f2f, #ff4949);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.team-member-position {
  font-family: Kodchasan;
  font-size: 150%;
  text-align: left;
  width: fit-content;
  background-image: linear-gradient(to right, #3f3b3b, #8c0909);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.team-member-about {
  font-family: Inder;
  font-size: 90%;
  text-align: justify;
  color: rgb(85, 85, 85);
}

@media screen and (max-width: 1000px) {
  .team-member-slide {
    width: 95%;
    height: 55vh;
    display: flex;
    flex-direction: column;
  }
  .team-member-details {
    width: 100%;
    height: 40%;
    text-align: center;
    row-gap: 20px;
    padding: 10px;
    align-items: center;
    row-gap: 5px;
  }
  .team-member-position {
    text-align: center;
  }
  .team-member-pic {
    height: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 600px) {
  .founders-heading {
    font-size: 180%;
  }
  .team-member-name {
    font-size: 100%;
  }
  .team-member-position {
    font-size: 80%;
  }
  .team-member-about {
    font-size: 50%;
  }
  .founders-box {
    width: 80vw;
  }
  .team-member-details {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/* Teams here */

#ceo {
  background-image: url("../Media/Team/Founder.png");
}

#coo {
  background-image: url("../Media/Team/COO.png");
}
