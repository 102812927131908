@import url("https://fonts.googleapis.com/css2?family=Monomaniac+One");
@import url("https://fonts.googleapis.com/css2?family=Kodchasan");

.App {
  text-align: center;
  background-color: #efeded;
  min-height: 100vh;
  cursor: none !important;
}

.canva {
  animation: float 2s ease-in-out infinite;
}

.title-layer-1 {
  font-size: 1200%;
  font-family: Monomaniac One;
  text-transform: uppercase;
  height: fit-content;
  background-image: linear-gradient(to bottom, white, grey, black);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  user-select: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
}

.title-layer-2 {
  user-select: none;
  font-size: 1200%;
  font-family: Monomaniac One;
  text-transform: uppercase;
  color: rgb(255, 255, 255, 0.24);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
}

.home-container {
  /* height: 90vh;
    background-image: url("./Media/Home_BG.png");
    background-attachment: fixed; 
    background-size: 100% 100%;
    padding-bottom: 6vh;
    filter: drop-shadow(2px 2px 5px grey); */
  height: 100vh;
  background-color: #161616;
}

.home-body {
  height: inherit;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.logo-holder {
  position: absolute;
  top: 10px;
  left: 10px;
}

.logo {
  width: 50px;
  height: 50px;
}

.home-caption {
  color: white;
  font-family: Kodchasan;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  font-size: 250%;
  text-shadow: 1px 1px 1px rgb(59, 54, 54);
  font-weight: 100;
  padding-bottom: 2vh;
}

#caption-word-change {
  height: 60px;
  overflow: hidden;
}

.home-caption-word {
  color: rgba(226, 19, 19, 0.8);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* .current-caption-word{
  animation: word-slide 2s;
  display: flex;
}

.past-caption-word{
  display: none;
} */

.progressbar {
  height: 5px;
  /* background-color: #A50000; */
  background-image: linear-gradient(to right, #a50000, #ee5757);
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes word-slide {
  0% {
    opacity: 0.2;
    color: rgb(221, 13, 13);
  }
  100% {
    opacity: 1;
    color: rgb(178, 12, 12, 0.8);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(-60px);
  }
  50% {
    transform: translateY(-45px); /* Move up by 5px */
  }
}

@media screen and (max-width: 600px) {
  .title-layer-1 {
    font-size: 600%;
    user-select: none;
  }
  .title-layer-2 {
    font-size: 600%;
    user-select: none;
  }
  .home-caption {
    font-size: 100%;
  }
  #caption-word-change {
    height: 25px;
  }
  .home-body {
    padding-bottom: 20vh;
    height: 80vh;
  }
  .logo {
    width: 30px;
    height: 30px;
    padding: 5px;
  }
}
