@import url("https://fonts.googleapis.com/css2?family=Inder");
@import url("https://fonts.googleapis.com/css2?family=Inika");
@import url("https://fonts.googleapis.com/css2?family=Kodchasan");

.features-container {
  width: 100lvw;
  min-height: 100vh;
  padding-top: 3%;
  border-top-style: double;
  background-color: #fff;
  margin-top: 0vh;
  background-image: linear-gradient(to bottom, #eaeaea, #e1cbcb);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.features-heading {
  font-family: Kodchasan;
  font-size: 300%;
  text-align: center;
  color: #b20c0c;
  text-transform: capitalize;
}

.features-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  column-gap: 10vw;
}

.feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  margin: 20px;
  animation: fade 2s forwards linear;
  width: 70vw;
}

.features-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 50vw;
  height: 70vh;
}

.feature-illustration {
  width: 25%;
  position: absolute;
}

.feature-head {
  font-family: Kodchasan;
  background-image: linear-gradient(to bottom, #ba2f2f, #ff4949);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 180%;
  font-weight: bold;
  text-transform: capitalize;
  padding: 15px;
}

.feature-body {
  font-family: Inder;
  color: black;
  width: 60vw;
  font-size: 110%;
  padding-left: 5vw;
  padding-right: 5vw;
  text-align: justify;
  background-image: linear-gradient(to right, #3f3b3b, #750c0c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ill1 {
  left: 12vw;
  margin-top: 27vh;
}

.ill2 {
  left: 12vw;
  margin-top: 35vh;
}

.ill3 {
  left: 14vw;
  margin-top: 40vh;
}

.ill4 {
  left: 17vw;
  margin-top: 30vh;
}

.ill5 {
  left: 19vw;
  margin-top: 28vh;
}

.ill6 {
  left: 20vw;
  margin-top: 24vh;
}

.ill7 {
  left: 18vw;
  margin-top: 30vh;
}

.ill8 {
  left: 15vw;
  margin-top: 36vh;
}

.ill9 {
  left: 20vh;
  margin-top: 33vh;
}

.disk {
  height: 80vh;
  width: 80vh;
  border: solid 2px rgb(178, 12, 12, 0.1);
  border-radius: 100%;
  position: absolute;
  margin-left: 20vw;
}

.inner-disk {
  height: 60vh;
  width: 60vh;
  border-radius: 100%;
  background-image: radial-gradient(rgb(249, 164, 164), rgb(198, 0, 23));
  margin: auto;
  margin-top: 10vh;
}

.disk-hole {
  height: 30vh;
  width: 30vh;
  border-radius: 100%;
  background-image: radial-gradient(#efeded, white);
  position: absolute;
  margin: auto;
  margin-top: 15vh;
  margin-left: 15vh;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.features-cards-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20%;
  margin-bottom: 10%;
  row-gap: 10vh;
}

.features-card {
  width: 70vw;
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feature-name {
  font-family: Kodchasan;
  background-image: linear-gradient(to bottom, #ba2f2f, #ff4949);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 150%;
  font-weight: bold;
  text-transform: capitalize;
  padding: 15px;
}

.feature-content-text {
  font-family: Inder;
  color: black;
  width: 60vw;
  font-size: 100%;
  padding-left: 5vw;
  padding-right: 5vw;
  text-align: justify;
  background-image: linear-gradient(to right, #3f3b3b, #750c0c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-illustration-image {
  width: 30%;
  margin-top: -10%;
}

@media screen and (max-width: 600px) {
  .feature-illustration-image {
    width: 50%;
    margin-top: -20%;
  }
  .features-heading {
    font-size: 200%;
  }
  .feature-name {
    font-size: 120%;
  }
  .feature-content-text {
    font-size: 100%;
  }
}
