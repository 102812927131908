@import url("https://fonts.googleapis.com/css2?family=Inder");
@import url("https://fonts.googleapis.com/css2?family=Inika");
@import url("https://fonts.googleapis.com/css2?family=Hahmlet");
@import url("https://fonts.googleapis.com/css2?family=Kodchasan");

.offers-heading {
  font-family: Kodchasan;
  font-size: 270%;
  text-align: center;
  text-transform: capitalize;
  color: #b20c0c;
  background-color: #efeded;
  padding-top: 20px;
}

.offers-head {
  font-family: Kodchasan;
  font-size: 180%;
  padding-left: 15px;
  text-align: left;
  text-transform: capitalize;
  background-image: linear-gradient(to bottom, #ba2f2f, #ff4949);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.offers-content {
  font-size: 110%;
  font-family: Inder;
  padding: 15px;
  text-align: justify;
  background-image: linear-gradient(to right, #3f3b3b, #750c0c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.offer {
  width: 40vw !important;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
}

.offer-mockup {
  perspective: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.offer-box {
  width: 40vw;
  height: 60vh;
  float: right;
  margin-right: 40px;
  border: solid 10px black;
  /* border-bottom: solid 20px #222222; */
  outline: solid 6px #393939;
  box-shadow: 0 0px 0 10px #afafaf;
  /* border-top-right-radius: 10px;
  border-top-left-radius: 10px; */
  border-radius: 10px;
  perspective: 600px;
  transform-style: preserve-3d;
  transform: rotate3d(0, 1, 0, -20deg);
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.offer-slider {
  display: flex;
  flex-direction: row;
}

.offer-pic {
  width: 40vw;
  height: 60vh;
}
