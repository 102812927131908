@import url("https://fonts.googleapis.com/css2?family=Kodchasan");
@import url("https://fonts.googleapis.com/css2?family=Hahmlet");
@import url("https://fonts.googleapis.com/css2?family=Inder");

.Package-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  row-gap: 5%;
  align-items: center;
  margin-top: 5vh;
  margin-bottom: 10vh;
}

.why-choose-us {
  width: inherit;
  display: flex;
  flex-direction: column;
  row-gap: 3%;
  align-items: center;
  margin-top: 2%;
}

.why-head-box {
  border: solid 2px grey;
  width: 60vh;
  height: 10vh;
  display: block;
  margin: auto;
}

.why-head {
  font-family: Kodchasan;
  font-size: 250%;
  text-align: center;
  background-color: #efeded;
  width: 65%;
  margin: auto;
  margin-top: -3vh;
}

@media screen and (max-width: 780px) {
  .why-head-box {
    width: 50vh;
  }
  .why-head {
    font-size: 260%;
    margin-top: -1vh;
  }
}

.highlight {
  color: #b20c0c;
}

.why-sections {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 20px;
  margin-top: 4%;
  flex-wrap: wrap;
}

.why-separator {
  height: inherit;
  width: 2px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.301);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.503);
}

@media screen and (max-width: 450px) {
  .why-separator {
    background-color: transparent;
    box-shadow: none;
  }
}

.why-section1 {
  color: #424242;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  text-align: right;
  width: 45vw;
}

.why-section2 {
  width: 45vw;
  margin-top: 3%;
  color: #b20c0c;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  text-align: left;
}

.why-point {
  font-family: Inder;
  font-size: 110%;
}

.why-check {
  color: #7aa731;
  margin-left: 20px;
  margin-right: 20px;
}

.why-point-content {
  color: #424242;
  font-size: 80%;
  width: 80%;
  font-weight: 100;
  padding-left: 60px;
  padding-right: 60px;
  animation: fade 1s;
  display: none;
  transition: 1s;
}

.why-section1 .why-point-content {
  float: right;
}

.why-point:hover .why-point-content {
  display: flex;
  transition: 1s;
}

.packages {
  margin-top: 3%;
  background-image: linear-gradient(
    to bottom right,
    white 0%,
    white 60%,
    #f4e8e8 60%,
    #f4e8e8 100%
  );
  width: 100vw;
  display: none;
  flex-direction: column;
  row-gap: 25px;
  padding: 20px;
}

.package-head {
  font-family: Inika;
  font-size: 270%;
  text-align: center;
  text-transform: capitalize;
  background-image: linear-gradient(to bottom, #ba2f2f, #ff4949);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.package-blocks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 5%;
  row-gap: 20px;
  margin-bottom: 5%;
}

.package-items {
  width: 25vw;
  border: solid 1px rgba(0, 0, 0, 0.201);
  border-radius: 20px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .package-items {
    width: 40vw;
  }
}

@media screen and (max-width: 700px) {
  .package-items {
    width: 60vw;
  }
}

.package-type-head {
  background-color: #9a0000;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  padding: 30px;
  font-family: monospace;
  text-transform: uppercase;
  font-size: 150%;
  color: white;
}

.package-type-body {
  color: black;
  font-family: Inder;
  font-size: 100%;
  padding: 15px;
  background-image: linear-gradient(
    to bottom right,
    #fbf2f2 0%,
    #fbf2f2 50%,
    #f4e8e8 50%,
    #f4e8e8 100%
  );
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  text-transform: capitalize;
}

.package-items:hover,
.design-package-items:hover {
  box-shadow: 0px 4px 10px rgba(53, 1, 1, 0.215);
  transform: scale(1.01);
  transition: 0.5s;
}

hr {
  margin: 15px;
}

.package-price {
  width: inherit;
  padding: 15px;
  text-align: center;
  border: solid 1px rgba(0, 0, 0, 0.201);
  border-radius: 20px;
  background-color: #f3f3f3;
  margin-top: 3%;
}

.price {
  font-family: Inder;
  font-weight: bold;
  font-size: 150%;
}

.additional {
  font-family: Inder;
  font-size: 100%;
  color: #424242;
}

.design-package {
  margin-left: 5%;
}

@media screen and (min-width: 900px) {
  .design-package {
    margin-top: -12%;
  }
}

.design-package-items {
  width: 32vw;
  border: solid 1px rgba(0, 0, 0, 0.201);
  border-radius: 20px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .design-package-items {
    width: 90vw;
  }
}

.design-package-body {
  color: black;
  font-family: Inder;
  font-size: 120%;
  padding: 5px;
  background-color: rgb(251, 242, 242, 0.4);
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  text-transform: capitalize;
  text-align: justify;
}

.design-pages {
  font-size: 70%;
}

.design-package-list-item {
  padding: 10px;
  list-style: disc;
}

.enquire {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.enquire-head {
  font-family: monospace;
  font-weight: bold;
  font-size: 250%;
  color: black;
  text-align: center;
}

.enquire-button {
  text-decoration: none;
  font-family: Inder;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #9a0000;
  box-shadow: 0 0 2px black;
  color: white;
  font-size: 150%;
  text-transform: uppercase;
  border-radius: 20px;
}

@keyframes fade {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 700px) {
  .why-head {
    font-size: 180%;
    width: 40vh;
  }
  .why-head-box {
    width: 45vh;
    height: 5vh;
    border: none;
    margin-bottom: 20px;
  }
  .why-separator {
    height: inherit;
    width: 2px;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.301);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.503);
  }
  .why-sections {
    display: flex;
    flex-wrap: no-wrap;
  }
  .why-section1 {
    width: 40vw;
    font-size: 60%;
    text-align: right;
  }
  .why-section2 {
    width: 40vw;
    font-size: 60%;
    text-align: left;
  }
  .why-check {
    display: none;
  }
  .why-point-content {
    display: none;
  }
  .why-point:hover .why-point-content {
    display: none;
  }
}
