@import url("https://fonts.googleapis.com/css2?family=Inder");
@import url("https://fonts.googleapis.com/css2?family=Inika");
@import url("https://fonts.googleapis.com/css2?family=Hahmlet");
@import url("https://fonts.googleapis.com/css2?family=Kantumruy");

.offer-container {
  min-width: 100vw;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100lvw;
  overflow: hidden;
  overflow-wrap: normal;
}

.bg-right {
  position: absolute;
  height: 100vh;
  right: 0;
}

.bg-left {
  position: absolute;
  height: 100vh;
  left: 0;
  transform: rotate(180deg);
}

.offers-heading {
  font-family: Kodchasan;
  font-size: 270%;
  text-align: center;
  text-transform: capitalize;
  color: #b20c0c;
  background-color: #efeded;
  padding-top: 20px;
}

.offer-screen {
  z-index: 1;
  margin-top: 6%;
  height: 75vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  border: none;
  box-shadow: none;
  outline: none;
  width: 95%;

  border: solid 10px black;
  /* border-bottom: solid 20px #222222; */
  outline: solid 6px #393939;
  box-shadow: 0 0px 0 10px #afafaf;
}

.offer-bg {
  width: 100%;
  height: 100%;
  display: none;
}

#current-offer-screen-bg {
  display: flex;
  transition-duration: 2s;
  animation: dissolve-in 1.5s ease;
}

#later-offer-screen-bg {
  transition-duration: 2s;
  animation: dissolve-out 2s ease-out;
  display: none;
}

.slide-pic {
  width: 20vw;
}

.offer-carousel {
  position: absolute;
  width: 95%;
  height: 90vh;
  margin: auto;
  column-gap: 30px;
  margin-top: 30%;
  overflow: hidden;
}

.offer-slide {
  height: 75vh;
  display: block;
  margin: auto;
  margin-left: 15%;
  margin-right: 15%;
}

.offer-element {
  background-color: rgb(48, 48, 48);
  background-image: url("../Media/Offer-block-outline.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
  padding-top: 15%;
  padding-bottom: 25px;
  width: 100%;
  height: 90%;
  display: block;
  margin: auto;
  align-self: center;
  margin-top: 5%;
  transform: rotate(0deg);
}

.offer-element-head {
  color: white;
  font-size: 180%;
  background-image: linear-gradient(
    to right,
    rgb(94, 0, 0),
    rgba(219, 2, 2, 0.806)
  );
  width: 85%;
  padding: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.offer-element-content {
  color: white;
  font-size: 160%;
  font-family: Inder;
  padding: 15px;
  text-align: justify;
  padding-left: 70px;
  padding-right: 60px;
}

#selected-element {
  transition-duration: 1s;
}

#not-selected-element {
  transform: scale(0.7);
  transition-duration: 1s;
}

@media screen and (max-width: 600px) {
  .offer-element-head {
    font-size: 120%;
  }
  .offer-element-content {
    font-size: 80%;
    padding: 35px;
  }
  .offer-carousel {
    width: 100%;
    display: flex;
    align-items: center;
    height: 55vh;
    margin-top: 0%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .offers-heading {
    font-size: 200%;
  }
  .offer-element {
    height: 50vh;
    margin-top: 0%;
  }
  .offer-slide {
    height: 50vh;
  }
}

@keyframes dissolve-in {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dissolve-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
