@import url("https://fonts.googleapis.com/css2?family=Inika");
@import url("https://fonts.googleapis.com/css2?family=Inder");
@import url("https://fonts.googleapis.com/css2?family=Kodchasan");

.about-container {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 6vh;
  overflow: hidden;
  margin-top: 0%;
}

.about-heading {
  font-family: Kodchasan;
  font-size: 270%;
  text-align: left;
  text-transform: capitalize;
  width: 80%;
  margin-left: 8%;
  background-image: linear-gradient(to bottom, #ba2f2f, #ff4949);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-content {
  width: 40%;
  font-size: 150%;
  margin-left: 8%;
  font-family: Inder;
  text-align: justify;
  background-image: linear-gradient(to right, #3f3b3b, #750c0c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 800px) {
  .about-content {
    width: 80%;
    font-size: 150%;
  }
}

@media screen and (max-width: 1100px) {
  .about-heading {
    width: 40%;
  }
}

@media screen and (max-width: 950px) {
  .about-heading {
    width: 80%;
    margin: 0px;
  }
}

.essence-link {
  text-decoration: none;
  width: 40%;
  z-index: -12;
  margin-left: 8%;
}

.more-arrow {
  background-color: #ff4949;
}

.know-more-text:hover {
  cursor: pointer;
  color: black;
}

.know-more-box {
  background-color: white;
  border-radius: 5px;
  width: 240px;
  height: 42px;
  box-shadow: 2px 2px 5px grey;
  perspective: 600px;
  display: flex;
  align-items: center;
  overflow: visible;
  margin: auto;
}

.essence-link:hover {
  transform: scale(1.5);
  cursor: pointer;
  background-color: #750c0c;
}

.know-more-button {
  width: 250px;
  height: 40px;
  margin-left: -10px;
  background-color: #b10d0d;
  border-radius: 5px;
  transform-style: preserve-3d;
  transform: rotate3d(0, 1, 0, -20deg);
  box-shadow: inset 0px 4px 4px rgb(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.know-more-text {
  font-size: 120%;
  text-decoration: none;
  font-family: Inder;
  text-align: center;
  color: white;
  text-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
}

.know-more-text:hover {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .about-container {
    min-height: 30vh;
    margin-top: 10vh;
  }
}

@media screen and (max-width: 600px) {
  .about-content {
    font-size: 110%;
  }
  .about-heading {
    font-size: 180%;
    text-align: center;
    margin-left: 0%;
  }
  .about-container {
    min-height: 60vh;
    margin-top: 10vh;
  }
  .about-content {
    width: 70vw;
    margin-left: 0%;
  }
  .about-container {
    align-items: center;
  }
}
