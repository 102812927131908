@import url("https://fonts.googleapis.com/css2?family=Inder");
@import url("https://fonts.googleapis.com/css2?family=Inika");
@import url("https://fonts.googleapis.com/css2?family=Kodchasan");

.project-heading {
  font-family: Kodchasan;
  font-size: 300%;
  text-align: center;
  text-transform: capitalize;
  background-image: linear-gradient(to bottom, #ba2f2f, #ff4949);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-section {
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
}

.project-sector {
  width: 100%;
  height: 50vh;
  background-color: white;
  box-shadow: inset 0px 0px 6px grey;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  flex-wrap: no-wrap;
  row-gap: 20px;
  justify-content: space-evenly;
}

.project {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-logo {
  max-width: 60%;
  filter: drop-shadow(2px 2px 5px rgb(128, 128, 128, 0.3));
}

.project-details {
  position: absolute;
  width: 250px;
  background-color: hsla(0, 0%, 80%, 0.2);
  backdrop-filter: blur(10px);
  padding: 10px;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 20px;
  display: none;
}

.project-name {
  font-family: Kodchasan;
  text-transform: uppercase;
  font-size: 150%;
  color: #b20c0c;
  font-weight: bold;
}

.project-desc {
  font-family: Inder;
  font-size: 100%;
  text-align: justify;
  background-image: linear-gradient(to right, #3f3b3b, #750c0c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.coming-soon {
  display: flex;
  backdrop-filter: blur(6px);
}

.coming-soon-text {
  font-size: 150%;
  text-transform: capitalize;
  background-image: linear-gradient(to right, #520505, #c71212);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project:hover .project-details {
  display: flex;
  animation: fade 0.3s forwards linear;
}

.coming-soon-project:hover .project-details {
  animation: none !important;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  .project-name {
    font-size: 120%;
  }

  .project-section {
    min-height: 30vh;
  }

  .project-heading {
    font-size: 180%;
    margin-top: 10vh;
  }
  .coming-soon-text {
    font-size: 100%;
  }

  .project-details {
    width: 20%;
  }

  .project-sector {
    height: 20vh;
  }
  .project-details {
    height: 10vh;
  }
  .project-name {
    font-size: 50%;
  }
  .project-desc {
    font-size: 30%;
    text-align: center;
  }
}
